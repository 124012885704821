import React from 'react'
import homeLink from '../../../utils/siteUrl'
import StarRatings from 'react-star-ratings'
import './card-product.scss'

const CardProduct = props => {

  const { className, product, parentSlug = "productos/", isQuality = false, onClick } = props;
  // console.log("product", product)

  const typeProduct = () => {
    let nameType = product.queTipoDeProductoEs.toLowerCase() || ''

    if (nameType.includes("nuevo")) {
      return "nuevo"
    } else if (nameType.includes("promoción") || nameType.includes("promocion")) {
      return "promocion"
    } else {
      return "generico"
    }
  }

  const getRedirection = () => {
    return homeLink + parentSlug + product.slug + (isQuality ? 'calificar/' : '')
  }

  return (
    <div className={"f-card-container " + (className ? className : '')}>
      <a href={getRedirection()} className={"f-card-product-" + typeProduct()} onClick={onClick}>
        {typeProduct() === "nuevo" && <span className="f-text-bold f-product-tag">¡Nuevo!</span>}
        {typeProduct() === "promocion" && <span className="f-product-tag">Promo</span>}
        <figure className="f-card-product-img">
          <img src={product.imagenDelProducto.file.url} alt={product.imagenDelProducto.description} />
        </figure>
        <span className="f-separator"></span>
        <div className="f-card-product-description">
          <StarRatings
            rating={product.ratingValue && product.ratingValue !== null ? product.ratingValue : 0}
            starRatedColor="#ffc645"
            starEmptyColor="#E6E6E6"
            numberOfStars={5}
            starDimension="24px"
            starSpacing="5px"
            name='rating'
          />
          <p className="f-text f-card-product-title">{product.nombreDelProducto}</p>
        </div>
      </a>
    </div>
  )
}

export default CardProduct;